import React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { history } from "./helpers";
import { PrivateRoute } from "./routes";
import DefaultLayout from "./pages/DefaultLayout";
import { ToastContainer } from "react-toastify";
import Login from "./pages/Login";
import "./scss/style.scss";

const App = () => {

  console.log("Gol Coco: 24/12/20");
  history.navigate = useNavigate();
  history.location = useLocation();

  const GetToastContainer = () => {
    return (
      <ToastContainer
        toastStyle={{ backgroundColor: "rgb(42 43 54 / 93%)", color: "white" }}
      />
    );
  };

  const GetRoutes = () => {
    return (
      <Routes>
        <Route
          exact
          path="/home/*"
          element={
            <PrivateRoute>
              <DefaultLayout />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Login />} />
      </Routes>
    );
  };

  return <div>{GetToastContainer()}{GetRoutes()}</div>;
};

export default React.memo(App);
