import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilCalculator,
  cilUserPlus,
  cilBeachAccess,
  cilStar,
  cilSettings,
  cilColorPalette,
  cilSchool,
  cilDevices,
  cilSwapHorizontal,
  cilEqualizer,
  cilSpeedometer,
  cilNotes,
  cilBell,
  cilDescription,
  cilPeople,
  cilGroup,
} from "@coreui/icons";
import { CNavItem, CNavTitle } from "@coreui/react-pro";

const _nav = [
  {
    component: CNavTitle,
    name: "Home",
  },
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/home/dashboard",
    icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: "Settings",
  },
  {
    component: CNavItem,
    name: "Edit Profile",
    to: "/home/profilesetting",
    icon: <CIcon icon={cilEqualizer} customClassName="nav-icon" style={{ transform: 'rotate(90deg)', transition: 'transform 0.2s' }} />,
  },
  {
    component: CNavItem,
    name: "Color & Sounds",
    to: "/home/playercolors",
    icon: <CIcon icon={cilColorPalette} customClassName="nav-icon" style={{ transform: 'rotate(90deg)', transition: 'transform 0.2s' }} />,
  },
  ,
  {
    component: CNavTitle,
    name: "Manage",
  },
  {
    component: CNavItem,
    name: "Playernotes",
    to: "/home/addremoveplayer",
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: "Admin Panel",
  },
  {
    component: CNavItem,
    name: "User Manager",
    to: "/home/addremoveuser",
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "App & Site Manager",
    to: "/home/addremovesite",
    icon: <CIcon icon={cilDevices} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Stake Convertor",
    to: "/home/stakeconvertor",
    icon: <CIcon icon={cilSwapHorizontal} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Server Status",
    to: "/home/serverstatus",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
];

export default _nav;
