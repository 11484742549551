import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilStar,
  cilColorPalette,
  cilSettings,
  cilDescription,
  cilEqualizer,
  cilNotes,
  cilBell
} from "@coreui/icons";
import { CNavItem, CNavTitle } from "@coreui/react-pro";

const _nav = [
  {
    component: CNavTitle,
    name: "Home",
  },
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/home/dashboard",
    icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Edit Profile",
    to: "/home/profilesetting",
    icon: <CIcon icon={cilEqualizer} customClassName="nav-icon" style={{ transform: 'rotate(90deg)', transition: 'transform 0.2s' }} />,
  },
  {
    component: CNavItem,
    name: "Color & Sounds",
    to: "/home/playercolors",
    icon: <CIcon icon={cilColorPalette} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: "Manage",
  },
  {
    component: CNavItem,
    name: "Playernotes",
    to: "/home/addremoveplayer",
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  },
];

export default _nav;
