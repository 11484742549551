import React, { useState, useEffect } from "react";
import {
  CCard,
  CCardHeader,
  CRow,
  CCol,
  CCardBody,
  CMultiSelect,
  CFormLabel,
  CFormSelect,
  CButtonGroup,
  CLoadingButton,
  CFormCheck,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
} from "@coreui/react-pro";
import { useSelector, useDispatch } from "react-redux";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import CreateProfileDlg from "../subcomponents/CreateProfileDlg";
import DelProfileDlg from "../subcomponents/DelProfileDlg";
import {
  GetProfileNames,
  JSonSettingObjectToSupaProfile,
} from "helpers/convert";
import {
  GetClubs,
  GetSites,
  GetClubsProfileSetting,
  GetAdvMinBuddyValue,
  SetAdvMinBuddyValue,
  GetGameTypeObjectFromMulSel,
  GetTableTypeObjectFromMulSel,
  GetSitesNoUnions,
  GetClubsProfileSettingNoUnion,
  UpdateGameTypeObjectFromMulSel,
} from "helpers/trasform";
import { history } from "../../helpers/history";
import {
  MIN_OPTIONS,
  BUDDY,
  LIGHTBUDDY,
  MEGABUDDY,
  ULTRABUDDY,
  UNKNOWN,
  MIN_PLYAER_TYPE,
  BLINDS,
} from "constant/constant";
import { UpdateProfile } from "helpers";
import { ShowFailToast, ShowSuccessToast } from "helpers/toast-alert";
import LoadingScreen from "react-loading-screen";
import FontAwesome from "react-fontawesome";
import { GetRealStackValuesInAdvSetting } from "helpers/admin/transform";
import { UpdateUserProfileName } from "helpers/admin/supabase";
import GameTypeMultiSelector from "./customizecomponent/GameTypeMultiSelector";

const ProfileSettingPageNew = () => {
  const dispatch = useDispatch();

  const profiles = useSelector((x) => x.profileState.profiles);

  if (profiles.length === 0) {
    return (
      <CCard className="mb-2 mt-2">
        <CCardBody
          style={{ background: "darkred", borderRadius: "6px", color: "white" }}
        >
          <h4>
            <FontAwesome
              name="triangle-exclamation"
              size="lg"
              style={{ color: "yellow" }}
            ></FontAwesome>{" "}
            You can never any clubs now. Please have a contact with
            adminstrator.{" "}
          </h4>
        </CCardBody>
      </CCard>
    );
  }

  const siteUnionsClubs = useSelector((x) => x.siteUnionClubState.data);
  const user_nid = useSelector((state) => state.login.user[0].id);
  const user = useSelector((state) => state.login.user[0]);
  const curProfileIndex = useSelector(
    (x) => x.profileState.currentProfileIndex
  );
  const disableProfile = useSelector(
    (state) => state.changeState.disableProfile
  );
  const showCreateNewProfileDlg = useSelector(
    (state) => state.changeState.showCreateNewProfileDlg
  );
  const showDeleteProfileDlg = useSelector(
    (state) => state.changeState.showDeleteProfileDlg
  );

  const sites = GetSites(siteUnionsClubs);
  const sites_nounions = GetSitesNoUnions(siteUnionsClubs);

  let ini_adv_site = null;
  let ini_adv_min_clubs = null;
  let ini_adv_club = null;
  if (sites.length !== 0) {
    ini_adv_site = sites[0]["value"];
    ini_adv_min_clubs = GetClubs(siteUnionsClubs, ini_adv_site);
    if (ini_adv_min_clubs.length !== 0) {
      ini_adv_club = ini_adv_min_clubs[0]["value"];
    } else {
      ini_adv_club = 1;
    }
  }

  const [updating, SetUpdating] = useState(false);
  const [isAdvSetting, SetIsAdvSetting] = useState(false);
  const [siteadv, Setsiteadv] = useState(ini_adv_site);
  const [clubsadv, Setclubsadv] = useState(ini_adv_min_clubs);
  const [clubadv, Setclubadv] = useState(ini_adv_club);
  const [stakeadv, SetStakeadv] = useState(0);

  const [gametypes, SetGameTypes] = useState(
    profiles[curProfileIndex]["gametype"]
  );
  const [tabletypes, SetTableTypes] = useState(
    profiles[curProfileIndex]["tabletype"]
  );
  const [playertypes, SetPlayerTypes] = useState(
    profiles[curProfileIndex]["playertype"]
  );
  const [blinds, SetBlinds] = useState(profiles[curProfileIndex]["blinds"]);

  const [maxMinPlayers, SetMaxMinPlayers] = useState(
    profiles[curProfileIndex]["maxmin_players"]
  );

  const [emptyseats, SetEmptySeats] = useState(
    profiles[curProfileIndex]["empty_seat_offset"]
  );
  const [minplayertypes, SetMinPlayerType] = useState(
    profiles[curProfileIndex]["min_player_type"]
  );
  const [siteclubs, SetSiteclubs] = useState(
    profiles[curProfileIndex]["siteclubs"]
  );
  const [settingadv, SetSettingadv] = useState(
    profiles[curProfileIndex]["avdsetting"]
  );

  const [minbuddyadv, SetMinbuddyadv] = useState(
    GetAdvMinBuddyValue(settingadv, siteadv, clubadv, 0)["mbuddy1"]
  );
  const [minmegabuddyadv, SetMinmegabuddyadv] = useState(
    GetAdvMinBuddyValue(settingadv, siteadv, clubadv, 0)["mbuddy2"]
  );

  const OnChangeSetPrice = (price, type) => {
    blinds[type] = price;
    SetBlinds(JSON.parse(JSON.stringify(blinds)));
    UpdateProfileSetting();
  };

  const OnChangeMaxMinPlayerCount = (value) => {
    maxMinPlayers['max'] = value['max'];
    maxMinPlayers['min'] = value['min'];
    SetMaxMinPlayers(value);
    UpdateProfileSetting();
  };

  const PTUnknownFormSwitch = (value, type) => {
    playertypes[type] = value.target.checked;
    SetPlayerTypes(JSON.parse(JSON.stringify(playertypes)));
    UpdateProfileSetting();
  };
  const DeleteButonOnClick = () => {
    dispatch({ type: "set", showCreateNewProfileDlg: false });
    dispatch({ type: "set", showDeleteProfileDlg: true });
    dispatch({ type: "set", disableProfile: true });
  };
  const CreateButonOnClick = () => {
    dispatch({ type: "set", showCreateNewProfileDlg: true });
    dispatch({ type: "set", showDeleteProfileDlg: false });
    dispatch({ type: "set", disableProfile: true });
  };

  const UpdateProfileSetting = () => {
    profiles[curProfileIndex]["playertype"] = playertypes;
    profiles[curProfileIndex]["blinds"] = blinds;
    profiles[curProfileIndex]["empty_seat_offset"] = emptyseats;
    profiles[curProfileIndex]["min_player_type"] = minplayertypes;
    profiles[curProfileIndex]["avdsetting"] = settingadv;
    profiles[curProfileIndex]["maxmin_players"] = maxMinPlayers;
    if (curProfileIndex === 0) {
      dispatch({ type: "set_profiles", payload: profiles });
    } else {
      UpdateProfile(
        user_nid,
        JSonSettingObjectToSupaProfile(profiles[curProfileIndex])
      ).then((ret) => {
        if (ret === 1) {
          dispatch({ type: "set_profiles", payload: profiles });
        }
      });
    }
  }

  const UpdateGameTypeProfileSetting = (valueGameTypes) => {
    profiles[curProfileIndex]["gametype"] = valueGameTypes;

    if (curProfileIndex === 0) {
      dispatch({ type: "set_profiles", payload: profiles });
    } else {
      UpdateProfile(
        user_nid,
        JSonSettingObjectToSupaProfile(profiles[curProfileIndex])
      ).then((ret) => {
        if (ret === 1) {
          dispatch({ type: "set_profiles", payload: profiles });
        }
      });
    }
  }

  const UpdateTableTypeProfileSetting = (valueTableTypes) => {
    profiles[curProfileIndex]["tabletype"] = valueTableTypes;
    if (curProfileIndex === 0) {
      dispatch({ type: "set_profiles", payload: profiles });
    } else {
      UpdateProfile(
        user_nid,
        JSonSettingObjectToSupaProfile(profiles[curProfileIndex])
      ).then((ret) => {
        if (ret === 1) {
          dispatch({ type: "set_profiles", payload: profiles });
        }
      });
    }
  }

  const UpdateSiteclubsProfileSetting = (valueSiteclubs) => {
    profiles[curProfileIndex]["siteclubs"] = valueSiteclubs;
    if (curProfileIndex === 0) {
      dispatch({ type: "set_profiles", payload: profiles });
    } else {
      UpdateProfile(
        user_nid,
        JSonSettingObjectToSupaProfile(profiles[curProfileIndex])
      ).then((ret) => {
        if (ret === 1) {
          dispatch({ type: "set_profiles", payload: profiles });
        }
      });
    }
  }

  const MinPlayerTypeOnChange = (a, type) => {
    minplayertypes[type] = parseInt(a.target.value);
    SetMinPlayerType(JSON.parse(JSON.stringify(minplayertypes)));
    UpdateProfileSetting();
  };

  useEffect(() => {
    if (history.location.pathname.includes("dashboard")) {
      dispatch({ type: "set", showProfile: true });
    } else {
      dispatch({ type: "set", showProfile: false });
    }
    SetGameTypes(profiles[curProfileIndex]["gametype"]);
    SetTableTypes(profiles[curProfileIndex]["tabletype"]);
    SetPlayerTypes(profiles[curProfileIndex]["playertype"]);
    SetBlinds(profiles[curProfileIndex]["blinds"]);
    SetEmptySeats(profiles[curProfileIndex]["empty_seat_offset"]);
    SetMinPlayerType(profiles[curProfileIndex]["min_player_type"]);
    SetSiteclubs(profiles[curProfileIndex]["siteclubs"]);
    SetSettingadv(profiles[curProfileIndex]["avdsetting"]);
    SetMaxMinPlayers(profiles[curProfileIndex]["maxmin_players"]);
    SetMinbuddyadv(
      GetAdvMinBuddyValue(settingadv, siteadv, clubadv, 0)["mbuddy1"]
    );
    SetMinmegabuddyadv(
      GetAdvMinBuddyValue(settingadv, siteadv, clubadv, 0)["mbuddy2"]
    );
  }, [dispatch, curProfileIndex]);

  const ClubMulSelOnChange = (event, site) => {
    var isprocessed = false;
    siteclubs.forEach((val) => {
      if (val["site_name"] === site) {
        isprocessed = true;
        let tm = [];
        event.forEach((val2) => {
          tm.push(val2["value"]);
        });
        val["club_names"] = tm;
      }
    });
    if (!isprocessed) {
      let tm = {};
      tm["site_name"] = site;
      let tmlist = [];
      event.forEach((val2) => {
        tmlist.push(val2["value"]);
      });
      if (tmlist.length !== 0) {
        tm["club_names"] = tmlist;
        siteclubs.push(tm);
      }
    }
    UpdateSiteclubsProfileSetting(JSON.parse(JSON.stringify(siteclubs)));
    // SetSiteclubs(JSON.parse(JSON.stringify(siteclubs)));
  };

  const ClubMulSelOnChangeNoUnion = (event) => {
    siteclubs.forEach((val) => {
      event.forEach((ele) => {
        if (val["site_name"] === ele["value"]) {
          let tm = [];
          tm.push(ele["value"]);
          val["club_names"] = tm;
        }
      });
    });

    siteclubs.forEach((val) => {
      if (IsSiteNoUnion(val["site_name"])) {
        if (!HasNoContainInMulChoooseEvent(event, val["site_name"])) {
          let tm = [];
          val["club_names"] = tm;
        }
      }
    });
    event.forEach((val) => {
      if (IsSiteNoUnion(val["value"])) {
        if (!HasNoContainInSiteClubs(siteclubs, val["value"])) {
          let ev = {};
          ev["site_name"] = val["value"];
          let tm = [];
          tm.push(val["value"]);
          ev["club_names"] = tm;
          siteclubs.push(ev);
        }
      }
    });
    UpdateSiteclubsProfileSetting(JSON.parse(JSON.stringify(siteclubs)));
  };

  const HasNoContainInMulChoooseEvent = (event, site_name) => {
    let ret = false;
    event.forEach((ele) => {
      if (ele["value"] === site_name) {
        ret = true;
      }
    });
    return ret;
  };

  const HasNoContainInSiteClubs = (event, site_name) => {
    let ret = false;
    event.forEach((ele) => {
      if (ele["site_name"] === site_name) {
        ret = true;
      }
    });
    return ret;
  };

  const IsSiteNoUnion = (ele1) => {
    let ret = false;
    sites_nounions.forEach((ele) => {
      if (ele["value"] === ele1) {
        ret = true;
      }
    });
    return ret;
  };

  const SelectProfileOnChange = (a) => {
    SetGameTypes(profiles[a.target.value]["gametype"]);
    SetTableTypes(profiles[a.target.value]["tabletype"]);
    SetPlayerTypes(profiles[a.target.value]["playertype"]);
    SetBlinds(profiles[a.target.value]["blinds"]);
    SetEmptySeats(profiles[a.target.value]["empty_seat_offset"]);
    SetMinPlayerType(profiles[curProfileIndex]["min_player_type"]);
    SetSiteclubs(profiles[a.target.value]["siteclubs"]);
    SetSettingadv(profiles[a.target.value]["avdsetting"]);
    SetMaxMinPlayers(profiles[a.target.value]["maxmin_players"]);

    Setsiteadv(sites[0]["value"]);
    let tclubs = GetClubs(siteUnionsClubs, sites[0]["value"]);
    if (tclubs.length === 0) {
      Setclubadv(1);
      Setclubsadv([]);
      let a1 = GetAdvMinBuddyValue(
        profiles[a.target.value]["avdsetting"],
        sites[0]["value"],
        null,
        0
      );
      SetStakeadv(0);
      SetMinbuddyadv(a1["mbuddy1"]);
      SetMinmegabuddyadv(a1["mbuddy2"]);
    } else {
      SetStakeadv(0);
      Setclubadv(tclubs[0]["value"]);
      Setclubsadv(tclubs);
      let a1 = GetAdvMinBuddyValue(
        profiles[a.target.value]["avdsetting"],
        sites[0]["value"],
        tclubs[0]["value"],
        0
      );
      SetMinbuddyadv(a1["mbuddy1"]);
      SetMinmegabuddyadv(a1["mbuddy2"]);
    }

    user.profile_name = profiles[a.target.value]["name"];
    UpdateUserProfileName(user).then((val) => { });
    dispatch({ type: "set_current_profile", payload: a.target.value });
  };

  const advClubSelectOnChange = (event) => {
    Setclubadv(event.target.value);
    let a = GetAdvMinBuddyValue(settingadv, siteadv, event.target.value, 0);
    SetStakeadv(0);
    SetMinbuddyadv(a["mbuddy1"]);
    SetMinmegabuddyadv(a["mbuddy2"]);
    UpdateProfileSetting();
  };

  const advStakeSelectOnChange = (event) => {
    SetStakeadv(event.target.value);
    let a = GetAdvMinBuddyValue(
      settingadv,
      siteadv,
      clubadv,
      event.target.value
    );
    SetMinbuddyadv(a["mbuddy1"]);
    SetMinmegabuddyadv(a["mbuddy2"]);
    UpdateProfileSetting();
  };

  const advSiteSelectOnChange = (event) => {
    Setsiteadv(event.target.value);
    let tclubs = GetClubs(siteUnionsClubs, event.target.value);
    Setclubsadv(tclubs);
    if (tclubs.length === 0) {
      Setclubadv(1);
      Setclubsadv([]);
      SetStakeadv(0);
      let a = GetAdvMinBuddyValue(settingadv, event.target.value, null, 0);
      SetMinbuddyadv(a["mbuddy1"]);
      SetMinmegabuddyadv(a["mbuddy2"]);
    } else {
      Setclubadv(tclubs[0]["value"]);
      SetStakeadv(0);
      let a = GetAdvMinBuddyValue(
        settingadv,
        event.target.value,
        tclubs[0]["value"],
        0
      );
      SetMinbuddyadv(a["mbuddy1"]);
      SetMinmegabuddyadv(a["mbuddy2"]);
    }
    UpdateProfileSetting();
  };

  const advMinBuddySelectOnChange = (event) => {
    SetMinbuddyadv(event.target.value);
    SetAdvMinBuddyValue(
      settingadv,
      siteadv,
      clubadv,
      stakeadv,
      event.target.value,
      minmegabuddyadv
    );
    UpdateProfileSetting();
  };

  const advMinMegaBuddySelectOnChange = (event) => {
    SetMinmegabuddyadv(event.target.value);
    SetAdvMinBuddyValue(
      settingadv,
      siteadv,
      clubadv,
      stakeadv,
      minbuddyadv,
      event.target.value
    );
    UpdateProfileSetting();
  };

  const ShowAdvSettingButtonOnClick = () => {
    SetIsAdvSetting(!isAdvSetting);
  };

  const IsAppsAndClubs = (val) => {
    let ret = false;
    sites_nounions.forEach((ele) => {
      if (ele["value"] === val["value"]) ret = true;
    });
    return ret;
  };

  const GetAdvSettingElement = () => {
    if (isAdvSetting) {
      return (
        <CRow>
          <CCol lg={12}>
            <CCard className="mb-2">
              <CCardHeader style={{ height: "48px" }}>
                <strong>Advanced Setting (Player Type Per Table)</strong>
              </CCardHeader>
              <CCardBody className="easyseat-text-align-left">
                <CTable small>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col"></CTableHeaderCell>
                      <CTableHeaderCell scope="col">Sites</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Clubs</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    <CTableRow>
                      <CTableHeaderCell scope="row"></CTableHeaderCell>
                      <CTableDataCell>
                        <CFormSelect
                          className="mt-2 mb-2"
                          disabled={disableProfile}
                          options={sites}
                          value={siteadv}
                          onChange={advSiteSelectOnChange}
                        ></CFormSelect>
                      </CTableDataCell>
                      <CTableDataCell>
                        <CFormSelect
                          className="mt-2 mb-2"
                          disabled={disableProfile}
                          options={clubsadv}
                          value={clubadv}
                          onChange={advClubSelectOnChange}
                        ></CFormSelect>
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableHeaderCell scope="row">Stake</CTableHeaderCell>
                      <CTableHeaderCell>Min Buddy</CTableHeaderCell>
                      <CTableHeaderCell>Min Mega Buddy</CTableHeaderCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableHeaderCell scope="row">
                        <CFormSelect
                          className="mt-2 mb-1"
                          disabled={disableProfile}
                          options={GetRealStackValuesInAdvSetting()}
                          value={stakeadv}
                          onChange={advStakeSelectOnChange}
                        ></CFormSelect>
                      </CTableHeaderCell>
                      <CTableDataCell>
                        <CFormSelect
                          className="mt-2 mb-1"
                          disabled={disableProfile}
                          options={MIN_OPTIONS}
                          value={minbuddyadv}
                          onChange={advMinBuddySelectOnChange}
                        ></CFormSelect>
                      </CTableDataCell>
                      <CTableDataCell>
                        <CFormSelect
                          className="mt-2 mb-1"
                          disabled={disableProfile}
                          options={MIN_OPTIONS}
                          value={minmegabuddyadv}
                          onChange={advMinMegaBuddySelectOnChange}
                        ></CFormSelect>
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      );
    }
  };

  return (
    <div className="align-items-center" style={{ maxWidth: "800px" }}>
      <CRow className="mt-2 mb-4">
        <CCol lg={5} className="mt-3">
          <CFormLabel>
            <strong>SELECT A PROFILE:</strong>
          </CFormLabel>
          <CFormSelect
            disabled={disableProfile}
            options={GetProfileNames(profiles)}
            value={curProfileIndex}
            onChange={SelectProfileOnChange}
          ></CFormSelect>
        </CCol>
        <CCol className="mt-3" lg={7}>
          <CFormLabel>
            <strong>CRUD THE PROFILES:</strong>
          </CFormLabel>
          <CButtonGroup role="group" style={{ width: "100%" }}>
            <CLoadingButton
              color="info"
              timeout={300}
              onClick={CreateButonOnClick}
            >
              Create
            </CLoadingButton>
            <CLoadingButton
              color="dark"
              timeout={300}
              onClick={DeleteButonOnClick}
            >
              Delete
            </CLoadingButton>
            {/* <CLoadingButton
              color="light"
              disabled={disableProfile}
              timeout={2000}
              disabledOnLoading={updating}
              loading={updating}
              onClick={UpdateButtonOnClick}
            >
              Update
            </CLoadingButton> */}
          </CButtonGroup>
        </CCol>
      </CRow>
      <CRow>
        {showCreateNewProfileDlg && <CreateProfileDlg />}
        {showDeleteProfileDlg && <DelProfileDlg />}
      </CRow>
      <CRow>
        <CCol lg={3}>
          <CCard>
            <CCardHeader style={{ height: "48px" }}>
              <strong>Player Type</strong>
            </CCardHeader>
            <CCardBody className="easyseat-text-align-left">
              <CFormCheck
                disabled={disableProfile}
                className="mt-1 mb-3"
                size="lg"
                label="Unknown"
                checked={playertypes[UNKNOWN]}
                onChange={(value) => {
                  PTUnknownFormSwitch(value, UNKNOWN);
                }}
              />
              <CFormCheck
                disabled={disableProfile}
                className="mt-1 mb-3"
                size="lg"
                label="Light Buddy"
                checked={playertypes[LIGHTBUDDY]}
                onChange={(value) => {
                  PTUnknownFormSwitch(value, LIGHTBUDDY);
                }}
              />
              <CFormCheck
                disabled={disableProfile}
                className="mt-1 mb-3"
                size="lg"
                label="Buddy"
                checked={playertypes[BUDDY]}
                onChange={(value) => {
                  PTUnknownFormSwitch(value, BUDDY);
                }}
              />
              <CFormCheck
                disabled={disableProfile}
                className="mt-1 mb-3"
                size="lg"
                label="Mega Buddy"
                checked={playertypes[MEGABUDDY]}
                onChange={(value) => {
                  PTUnknownFormSwitch(value, MEGABUDDY);
                }}
              />
              <CFormCheck
                disabled={disableProfile}
                className="mt-1 mb-3"
                size="lg"
                label="Ultra Buddy"
                checked={playertypes[ULTRABUDDY]}
                onChange={(value) => {
                  PTUnknownFormSwitch(value, ULTRABUDDY);
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol lg={7}>
          <CCard className="mb-2 easyseat-prof-block1 easyseat-margin-top-blind">
            <CCardHeader style={{ height: "48px" }}>
              <strong>Blinds</strong>
              <CButton
                color="dark"
                style={{
                  padding: "2px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  marginLeft: "50%",
                  display: "none",
                }}
                onClick={ShowAdvSettingButtonOnClick}
              >
                Adv Setting
              </CButton>
            </CCardHeader>
            <CCardBody className="easyseat-text-align-left easyseat-prof-block1-body">
              <div
                className="mt-1 mb-3 px-2"
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <CFormLabel
                  className="easyseat-blinds-label px-1"
                  style={{
                    textAlign: "center",
                    width: "50%",
                    marginTop: "-2%",
                  }}
                >
                  Unknown
                </CFormLabel>
                <InputRange
                  // draggableTrack = {true}
                  disabled={!playertypes[UNKNOWN]}
                  className="price--range"
                  maxValue={BLINDS.length - 1}
                  minValue={0}
                  step={1}
                  formatLabel={(price) => `${BLINDS[price]}`}
                  value={blinds[UNKNOWN]}
                  onChange={(price) => OnChangeSetPrice(price, UNKNOWN)}
                  onChangeComplete={(price) => { }}
                ></InputRange>
              </div>
              <div
                className="mt-1 mb-3 px-2"
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <CFormLabel
                  className="easyseat-blinds-label px-1"
                  style={{
                    textAlign: "center",
                    width: "50%",
                    marginTop: "-2%",
                  }}
                >
                  Light Buddy
                </CFormLabel>
                <InputRange
                  disabled={!playertypes[LIGHTBUDDY]}
                  className="price--range"
                  maxValue={BLINDS.length - 1}
                  minValue={0}
                  step={1}
                  formatLabel={(price) => `${BLINDS[price]}`}
                  value={blinds[LIGHTBUDDY]}
                  onChange={(price) => OnChangeSetPrice(price, LIGHTBUDDY)}
                  onChangeComplete={(price) => { }}
                />
              </div>
              <div
                className="mt-1 mb-3 px-2"
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <CFormLabel
                  className="easyseat-blinds-label px-1"
                  style={{
                    textAlign: "center",
                    width: "50%",
                    marginTop: "-2%",
                  }}
                >
                  Buddy
                </CFormLabel>
                <InputRange
                  disabled={!playertypes[BUDDY]}
                  className="price--range"
                  maxValue={BLINDS.length - 1}
                  minValue={0}
                  step={1}
                  formatLabel={(price) => `${BLINDS[price]}`}
                  value={blinds[BUDDY]}
                  onChange={(price) => OnChangeSetPrice(price, BUDDY)}
                  onChangeComplete={(price) => { }}
                />
              </div>
              <div
                className="mt-1 mb-3 px-2"
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <CFormLabel
                  className="easyseat-blinds-label px-1"
                  style={{
                    textAlign: "center",
                    width: "50%",
                    marginTop: "-2%",
                  }}
                >
                  Mega Buddy
                </CFormLabel>
                <InputRange
                  disabled={!playertypes[MEGABUDDY]}
                  className="price--range"
                  maxValue={BLINDS.length - 1}
                  minValue={0}
                  step={1}
                  formatLabel={(price) => `${BLINDS[price]}`}
                  value={blinds[MEGABUDDY]}
                  onChange={(price) => OnChangeSetPrice(price, MEGABUDDY)}
                  onChangeComplete={(price) => { }}
                />
              </div>
              <div
                className="mt-1 mb-3 px-2"
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <CFormLabel
                  className="easyseat-blinds-label px-1"
                  style={{
                    textAlign: "center",
                    width: "50%",
                    marginTop: "-2%",
                  }}
                >
                  Ultra Buddy
                </CFormLabel>
                <InputRange
                  disabled={!playertypes[ULTRABUDDY]}
                  className="price--range"
                  name="Light Buddy"
                  maxValue={BLINDS.length - 1}
                  minValue={0}
                  step={1}
                  formatLabel={(price) => `${BLINDS[price]}`}
                  value={blinds[ULTRABUDDY]}
                  onChange={(price) => OnChangeSetPrice(price, ULTRABUDDY)}
                  onChangeComplete={(price) => { }}
                />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol lg={2} className="easyseat-avg-setting2">
          <CCard className="mb-1 easyseat-avg-setting5">
            <CCardHeader style={{ height: "48px" }}>
              <strong className="easyseat-blinds-label-show">Minimum</strong>
              <strong className="easyseat-blinds-label">
                Minimum Player Type
              </strong>
            </CCardHeader>
            <CCardBody className="easyseat-text-align-left">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <CFormLabel
                  className="easyseat-blinds-label"
                  style={{
                    textAlign: "center",
                    width: "60%",
                    marginTop: "4px",
                  }}
                >
                  Unknown
                </CFormLabel>
                <CFormSelect
                  disabled={!playertypes[UNKNOWN]}
                  style={{
                    display: "inline-block",
                    width: "100%",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  options={MIN_PLYAER_TYPE}
                  value={minplayertypes[UNKNOWN]}
                  onChange={(a) => {
                    MinPlayerTypeOnChange(a, UNKNOWN);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: "3px",
                }}
              >
                <CFormLabel
                  className="easyseat-blinds-label"
                  style={{
                    textAlign: "center",
                    width: "60%",
                    marginTop: "4px",
                  }}
                >
                  Light Buddy
                </CFormLabel>
                <CFormSelect
                  disabled={!playertypes[LIGHTBUDDY]}
                  style={{
                    display: "inline-block",
                    width: "100%",
                    fontSize: "14px",
                    marginTop: "2px",
                    cursor: "pointer",
                  }}
                  options={MIN_PLYAER_TYPE}
                  value={minplayertypes[LIGHTBUDDY]}
                  onChange={(a) => {
                    MinPlayerTypeOnChange(a, LIGHTBUDDY);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: "3px",
                }}
              >
                <CFormLabel
                  className="easyseat-blinds-label"
                  style={{
                    textAlign: "center",
                    width: "60%",
                    marginTop: "4px",
                  }}
                >
                  Buddy
                </CFormLabel>
                <CFormSelect
                  disabled={!playertypes[BUDDY]}
                  style={{
                    display: "inline-block",
                    width: "100%",
                    fontSize: "14px",
                    marginTop: "2px",
                    cursor: "pointer",
                  }}
                  options={MIN_PLYAER_TYPE}
                  value={minplayertypes[BUDDY]}
                  onChange={(a) => {
                    MinPlayerTypeOnChange(a, BUDDY);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: "4px",
                }}
              >
                <CFormLabel
                  className="easyseat-blinds-label"
                  style={{
                    textAlign: "center",
                    width: "60%",
                    marginTop: "4px",
                  }}
                >
                  Mega Buddy
                </CFormLabel>
                <CFormSelect
                  disabled={!playertypes[MEGABUDDY]}
                  style={{
                    display: "inline-block",
                    width: "100%",
                    fontSize: "14px",
                    marginTop: "2px",
                    cursor: "pointer",
                  }}
                  options={MIN_PLYAER_TYPE}
                  value={minplayertypes[MEGABUDDY]}
                  onChange={(a) => {
                    MinPlayerTypeOnChange(a, MEGABUDDY);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: "4px",
                }}
              >
                <CFormLabel
                  className="easyseat-blinds-label"
                  style={{
                    textAlign: "center",
                    width: "60%",
                    marginTop: "4px",
                  }}
                >
                  Ultra Buddy
                </CFormLabel>
                <CFormSelect
                  disabled={!playertypes[ULTRABUDDY]}
                  style={{
                    display: "inline-block",
                    width: "100%",
                    fontSize: "14px",
                    marginTop: "2px",
                    cursor: "pointer",
                  }}
                  options={MIN_PLYAER_TYPE}
                  value={minplayertypes[ULTRABUDDY]}
                  onChange={(a) => {
                    MinPlayerTypeOnChange(a, ULTRABUDDY);
                  }}
                />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {GetAdvSettingElement()}
      <CRow>
        <CCol lg={12}>
          <CCard className="mb-2">
            <CCardHeader>
              <strong>Min-Max Player Count</strong>
            </CCardHeader>
            <CCardBody className="easyseat-text-align-left easyseat-prof-block1-body">
              <InputRange
                disabled={disableProfile}
                className="price--range"
                name="Max-Min Player's Count"
                maxValue={6}
                minValue={1}
                step={1}
                allowSameValues={true}
                formatLabel={(value) => `${value}`}
                value={maxMinPlayers}
                onChange={(value) => {
                  OnChangeMaxMinPlayerCount(value);
                }}
                onChangeComplete={(price) => { }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        {/* <CCol lg={6}>
          <CCard className="mb-2 easyseat-profile-gametype-card">
            <CCardHeader>
              <strong>Game Types</strong>
            </CCardHeader>
            <CCardBody className="easyseat-text-align-left">
              <div
                style={{
                  width: "100%",
                  display: "inline-block",
                  height: "100px",
                }}
              >
                <CMultiSelect
                  style={{ cursor: "pointer" }}
                  disabled={disableProfile}
                  options={profiles[curProfileIndex]["gametype"]}
                  selectionType="tags"
                  optionsStyle="checkbox"
                  selectAll={true}
                  selectAllLabel="Select all"
                  placeholder="Please select the game types..."
                  search={false}
                  onChange={(a) => {
                    UpdateGameTypeProfileSetting(GetGameTypeObjectFromMulSel(a));
                  }}
                ></CMultiSelect>
              </div>
            </CCardBody>
          </CCard>
        </CCol> */}

        <CCol lg={6}>
          <CCard className="mb-2 easyseat-profile-gametype-card">
            <CCardHeader>
              <strong>Game Types</strong>
            </CCardHeader>
            <CCardBody className="easyseat-text-align-left">
              <div
                style={{
                  width: "100%",
                  display: "inline-block",
                  height: "100px",
                }}
              >
                <GameTypeMultiSelector
                  disableProfile={disableProfile}
                  options={profiles[curProfileIndex]["gametype"]}
                  UpdateGameTypeProfileSetting={UpdateGameTypeProfileSetting}
                ></GameTypeMultiSelector>
              </div>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol lg={6}>
          <CCard className="mb-2 easyseat-profile-tabletype-card">
            <CCardHeader>
              <strong>Table Types</strong>
            </CCardHeader>
            <CCardBody className="easyseat-text-align-left">
              <div
                style={{
                  display: "inline-block",
                  width: "100%",
                  height: "100px",
                }}
              >
                <CMultiSelect
                  style={{ cursor: "pointer" }}
                  disabled={disableProfile}
                  options={profiles[curProfileIndex]["tabletype"]}
                  selectionType="tags"
                  selectAll={true}
                  selectAllLabel="Select all"
                  placeholder="Please select the table types..."
                  search={false}
                  onChange={(a) => {
                    UpdateTableTypeProfileSetting(GetTableTypeObjectFromMulSel(a));
                  }}
                />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow>
        <CCol lg={12}>
          <CCard className="mb-2 easyseat-prof-block2">
            <CCardHeader>
              <strong>Apps & Clubs</strong>
            </CCardHeader>
            <CCardBody
              className="easyseat-text-align-left"
              style={{ paddingTop: "2px" }}
            >
              {sites.map((val) => {
                if (!IsAppsAndClubs(val)) {
                  return (
                    <div
                      key={val["value"]}
                      style={{
                        display: "inline-block",
                        width: "100%",
                        marginTop: "4px",
                      }}
                    >
                      <CMultiSelect
                        style={{ cursor: "pointer" }}
                        className="easyseat-profile-clubs-mulselect"
                        disabled={disableProfile}
                        options={GetClubsProfileSetting(
                          siteUnionsClubs,
                          val["value"],
                          siteclubs
                        )}
                        search={false}
                        selectionType="tags"
                        selectAllLabel="Select all"
                        selectionTypeCounterText="Please select any clubs..."
                        label={`${val["value"]}`}
                        onChange={(event) => {
                          ClubMulSelOnChange(event, val["value"]);
                        }}

                      ></CMultiSelect>
                    </div>
                  );
                }
              })}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg={12}>
          <CCard className="mb-2 easyseat-prof-block3">
            <CCardHeader>
              <strong>Sites</strong>
            </CCardHeader>
            <CCardBody className="easyseat-text-align-left">
              <div
                style={{
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <CMultiSelect
                  className="easyseat-profile-clubs-mulselect"
                  disabled={disableProfile}
                  options={GetClubsProfileSettingNoUnion(
                    siteUnionsClubs,
                    sites_nounions,
                    siteclubs
                  )}
                  selectAllLabel="Select all"
                  search={false}
                  selectionType="tags"
                  selectionTypeCounterText="Please select any clubs..."
                  onChange={(event) => {
                    ClubMulSelOnChangeNoUnion(event);
                  }}
                ></CMultiSelect>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <hr />
      <LoadingScreen
        loading={updating}
        bgColor="rgba(255,255,255,0.1)"
        spinnerColor="#000000"
      >
        <div></div>
      </LoadingScreen>
    </div>
  );
};
export default React.memo(ProfileSettingPageNew);
